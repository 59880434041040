<template>
    <div>
        <Table :columns="columns" :data="list" size="small" stripe :loading="loadData" :row-class-name="rowClassName"></Table>
        <div class="paging_style">
            <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator @on-change="handlePageNumberChange"></Page>
        </div>
    </div>
</template>

<script>
import { getContinuousPage } from '@/api/order/continuous'
import { formatOrderSaleType } from '@/utils/tagStatus'
import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    orderId: {
      type: [Number, String],
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    customskuId: {
      type: Number
    }
  },
  data () {
    return {
      query: {
        orderId: this.orderId,
        productId: this.product.productId,
        subitemproductId: this.product.subitemproductId,
        pageNumber: 1,
        pageSize: 15,
        keyword: '',
        startDate: this.product.startDate,
        endDate: this.product.endDate,
        priceType: this.product.priceType,
        loadData: false
      },
      columns: [
        { title: '连封名称', width: 120, key: 'productskuName' },
        { title: '所属站台', width: 120, key: 'stationName' },
        { title: '所属资产', width: 120, key: 'assetName' },
        {
          title: '媒介使用费',
          width: 110,
          render: (h, params) => {
            return h('span',
              toMoney(params.row.usePrice)
            )
          }
        },
        {
          title: '采购类型',
          align: 'center',
          width: 100,
          render: (h, params) => {
            return formatOrderSaleType(h, params.row.type)
          }
        }
      ],
      list: [],
      total: 0
    }
  },
  created () {
    this.initColumn()
    this.initPurchaseList()
  },
  methods: {
    initColumn () {
      if (this.hiddenOrderPoint) { return }

      this.columns.splice(3, 0, {
        title: '包含资源',
        render: (h, data) => {
          return h('div', [
            data.row.productDetailResourceVOList.map(resource => {
              return h('span', { attrs: { class: 'remark' }, style: { 'margin-right': '5px', 'font-weight': 'bold' } }, resource.resourceCode + ',')
            })
          ])
        }
      })
    },
    rowClassName (row, index) {
      return row.conflict ? 'table-conflict-row' : ''
    },
    initPurchaseList () {
      this.loadData = true
      this.query.customskuId = this.customskuId || undefined
      this.query.orderId = this.orderId
      this.query.productId = this.customskuId ? this.product.curProductId : this.product.productId
      getContinuousPage(this.query).then(res => {
        this.total = res.totalRow
        this.list = res.list
        this.query.pageNumber = res.pageNumber
        this.loadData = false
      })
    },
    handlePageNumberChange (page) {
      this.query.pageNumber = page
      this.initPurchaseList()
    }
  }
}
</script>
