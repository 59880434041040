<template>
    <div>
        <h4 class="workplatform-title">资源站点分布</h4>
        <p class="p-t-5 p-r-10 p-l-10" v-for="(item) in assets" :key="item.assetId">
            <span class="title">{{item.assetName}}</span>
            <a v-for="(station,sindex) in (StationDistribution.filter(x=>x.key === item.assetId).length>0?StationDistribution.filter(x=>x.key === item.assetId)[0].stationQuantity:[])"
              style="padding: 5px;" :key="'a'+ sindex" @click="showStationSvg(station)">{{station.stationName + '('+station.quantity+')' }}</a>
        </p>

        <SvgStation ref="svgStation" :stationSvgModal.sync="svgModal.isShow" :orderId="orderId" :stationId="svgModal.stationId"
          :searchSchedules="[{startDate: svgModal.startDate, endDate: svgModal.endDate}]" :svgShowType="svgModal.svgShowType"
          showStationPortray
          @svg-finished="svgOrThreeFinished" @handleChange3dMode="handleBeginRevice3d"></SvgStation>

        <ThreeStation ref="threeStation" @three-finished="svgOrThreeFinished" @handleChangeSvgMode="showStationSvg"/>
    </div>
</template>

<script>
import SvgStation from '@/components/svg/SvgStation'
import ThreeStation from '@/components/svg/ThreeStation'
import { getStationLevelList, getProductDetailPageByOrder, getResourceCountByOrderId } from '@/api/order/productdetail'

export default {
  props: {
    orderId: {
      type: [Number, String],
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    customskuId: {
      type: Number
    }
  },
  components: {
    SvgStation, ThreeStation
  },
  data () {
    return {
      assets: [],
      currentasset: '',
      StationDistribution: [],
      StationLevelQuantity: [],
      query: {
        orderId: this.orderId,
        productId: this.product.productId,
        subitemproductId: this.product.subitemproductId,
        pageNumber: 1,
        pageSize: 15,
        keyword: '',
        startDate: this.product.startDate,
        endDate: this.product.endDate,
        priceType: this.product.priceType
      },
      svgModal: {
        isShow: false,
        stationName: '',
        stationId: 0,
        startDate: this.product.startDate,
        endDate: this.product.endDate,
        svgShowType: 3 // 3订单详情资源展示
      }
    }
  },
  mounted () {
    this.initpage()
  },
  methods: {
    initpage () {
      this.GetAssetGroup()
    },
    showStationSvg (station) {
      this.svgModal.isShow = true
      if (station.stationId) {
        this.svgModal.stationId = station.stationId
        this.svgModal.stationName = station.stationName
      }
    },
    handleBeginRevice3d (stationId) {
      this.$refs.threeStation.initData(stationId)
    },
    svgOrThreeFinished () {
      if (this.svgModal.isShow) {
        this.$refs.svgStation.initShowBrand()
      }

      const schedule = JSON.stringify([{ startDate: this.product.startDate, endDate: this.product.endDate }])
      const resourceSearchData = {
        orderId: this.orderId,
        stationIds: this.svgModal.stationId,
        schedules: schedule,
        productId: this.customskuId ? this.product.curProductId : this.product.productId,
        customskuId: this.customskuId,
        pageSize: 200,
        pageNumber: 1
      }

      getProductDetailPageByOrder(resourceSearchData).then(res => {
        if (this.svgModal.isShow) {
          this.$refs.svgStation.tagResource(res.list)
          this.$refs.svgStation.choseSpinShow()
        } else {
          this.$refs.threeStation.tagResource(res.list)
        }
      })
    },
    GetAssetGroup () {
      const data = {
        orderId: this.orderId,
        productId: this.customskuId ? this.product.curProductId : this.product.productId,
        startDate: this.product.startDate,
        endDate: this.product.endDate,
        customskuId: this.customskuId || undefined,
        priceType: this.product.priceType
      }
      getStationLevelList(data).then(res => {
        this.assets = res
        if (this.assets.length > 0) {
          this.currentasset = this.assets[0]
          if (this.product.productType !== 5 && this.product.productType !== 4) {
            this.getStationDistribution()
          }
        }
      })
    },
    getStationDistribution () {
      this.StationDistribution = []

      const schedule = JSON.stringify([{ startDate: this.product.startDate, endDate: this.product.endDate }])
      const data = {
        orderId: this.orderId,
        productId: this.customskuId ? this.product.curProductId : this.product.productId,
        assetIds: JSON.stringify(this.assets.map(x => x.assetId)),
        schedules: schedule,
        customskuId: this.customskuId || undefined,
        priceType: this.product.priceType
      }
      getResourceCountByOrderId(data).then(res => {
        this.StationLevelQuantity = []
        this.assets.forEach(asset => {
          const assetStation = res.filter(x => x.assetId === asset.assetId)
          this.StationDistribution.push({ key: asset.assetId, stationQuantity: assetStation })

          asset.stationLevelBeanList.forEach(level => {
            if (this.StationLevelQuantity.filter(x => x.levelName === level.levelName).length > 0) {
              const currentLevel = this.StationLevelQuantity.filter(x => x.levelName === level.levelName)[0]
              currentLevel.quantity += level.quantity
            } else {
              this.StationLevelQuantity.push({ levelName: level.levelName, quantity: level.quantity })
            }
          })
        })
      })
    }
  },
  watch: {
    product (val) {
      this.initpage()
    }
  }
}
</script>
