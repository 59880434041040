<template>
    <div>
        <!-- <Row :gutter="8" class="m-b-5">
            <i-col span="24">
            <i-input  v-model="query.keyword" placeholder="关键字">
                <Button slot="append" icon="ios-search" @click="ReLoadData"></Button>
            </i-input>
            </i-col>
        </Row> -->

        <Table :columns="Fixedcolumndata" size="small" stripe :data="tableData" :row-class-name="rowClassName"></Table>
        <div class="paging_style">
            <Page size="small"  :total="total" :page-size="query.pageSize" show-total show-elevator :current="query.pageNumber" @on-change="changePage"></Page>
        </div>
    </div>
</template>

<script>
import { getProductDetailPageByOrder } from '@/api/order/productdetail'
import { formatOrderSaleType } from '@/utils/tagStatus'
import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    orderId: {
      type: [Number, String],
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    customskuId: {
      type: Number
    }
  },
  data () {
    return {
      tableData: [],
      Fixedcolumndata: [ // 显示地铁灯箱或者路面媒体
        {
          title: '资源编号',
          key: 'resourceCode'
        },
        {
          title: '资源等级',
          key: 'stationLevelName'
        },
        {
          renderHeader: (h, params) => {
            if (this.product.productType === 5) {
              return h('span', '站台编号')
            } else {
              return h('span', '所属楼层')
            }
          },
          render: (h, params) => {
            if (this.product.productType === 5) {
              return h('span', params.row.stationCode)
            } else {
              return h('span', params.row.floor)
            }
          }
        },
        {
          title: '位置',
          key: 'positionName'
        },
        {
          title: '资源类型',
          render: (h, params) => {
            let showLable = params.row.resourcetypeName
            if (params.row.duration > 1) {
              showLable += ' (' + params.row.duration + '秒 | '
              showLable += (params.row.tduration / params.row.duration) + '次/天)'
            }

            if (params.row.side > 1) {
              showLable += ' (' + params.row.side + ' 面)'
            }

            return h('span', showLable)
          }
        },
        {
          renderHeader: (h, params) => {
            if (this.product.productType === 5) {
              return h('span', '所属站点/站点类型')
            } else {
              return h('span', '所属站台')
            }
          },
          render: (h, params) => {
            if (this.product.productType === 5) {
              return h('span', params.row.stationName + '[' + params.row.stationTypeName + ']')
            } else {
              return h('span', params.row.stationName)
            }
          }
        },
        { title: '所属资产', key: 'assetName' },
        {
          title: '采购类型',
          align: 'center',
          render: (h, data) => {
            if (this.query.priceType === 0) {
              return formatOrderSaleType(h, this.query.priceType)
            } else {
              return formatOrderSaleType(h, data.row.type)
            }
          }
        }
      ],
      query: {
        orderId: this.orderId,
        productId: this.product.productId,
        subitemproductId: this.product.subitemproductId,
        pageNumber: 1,
        pageSize: 10,
        keyword: '',
        startDate: this.product.startDate,
        endDate: this.product.endDate,
        priceType: this.product.priceType
      },
      total: 0,
      currentProduct: {}
    }
  },
  created () {
    this.initColumn()
    this.initPurchaseList()
  },
  methods: {
    initColumn () {
      if (!this.customskuId) {
        this.Fixedcolumndata.push(
          {
            title: '价格',
            render: (h, data) => {
              const showSpan = []
              if (this.query.priceType === 0) {
                showSpan.push(h('span', toMoney(data.row.settlementPrice)))
                showSpan.push(h('span', { style: { color: '#ed4014' } }, '（' + (data.row.discount * 10).toFixed(2) + '折）'))
              } else {
                showSpan.push(h('span', toMoney(data.row.useprice)))
              }

              return h('div', showSpan)
            }
          }
        )
      }
    },
    rowClassName (row, index) {
      return row.conflict ? 'table-conflict-row' : ''
    },
    initPurchaseList () {
      if (this.hiddenOrderPoint) { return }

      this.query.productId = this.customskuId ? this.product.curProductId : this.product.productId
      this.query.orderId = this.orderId
      this.query.customskuId = this.customskuId || undefined
      getProductDetailPageByOrder(this.query).then(res => {
        this.tableData = res.list
        this.total = res.totalRow
        this.query.pageNumber = res.pageNumber
      })
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPurchaseList()
    },
    ReLoadData () {
      this.query.pageNumber = 1
      this.initPurchaseList()
    }
  }
}
</script>
