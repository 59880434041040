<style>
.deleted {
  text-decoration-line: line-through;
  color: gray;
}
</style>

<template>
  <div style="text-align:center">
     <Table  stripe :data="list" :columns="columns" height="450"></Table>
    </div>
</template>

<script>
import { orderResourceChanges } from '@/api/order/orderitem'
import { formatOrderChangeType } from '@/utils/tagStatus'

export default {
  props: {
    orderId: null
  },
  data () {
    return {
      list: [],
      columns: [
        {
          title: '原始名称',
          render: (h, data) => {
            if (data.row.nameTo && data.row.nameTo !== '') {
              return h('div', [
                h(
                  'span',
                  {
                    attrs: {
                      class: 'deleted'
                    }
                  },
                  data.row.nameFrom
                ),
                h('br'),
                h(
                  'span',
                  {
                    style: {
                      'font-weight': 'bold'
                    }
                  },
                  data.row.nameTo
                )
              ])
            } else {
              return h('span', data.row.nameFrom)
            }
          }
        },
        {
          title: '档期开始日期',
          render: (h, data) => {
            if (!data.row.startDateto || data.row.startDatefrom === data.row.startDateto) {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      'font-weight': 'bold'
                    }
                  },
                  data.row.startDatefrom
                )
              ])
            } else {
              return h('div', [
                h(
                  'span',
                  {
                    attrs: {
                      class: 'deleted'
                    }
                  },
                  data.row.startDatefrom
                ),
                h('br'),
                h(
                  'span',
                  {
                    style: {
                      'font-weight': 'bold'
                    }
                  },
                  data.row.startDateto
                )
              ])
            }
          }
        },
        {
          title: '档期结束日期',
          render: (h, data) => {
            if (!data.row.endDateto || data.row.endDatefrom === data.row.endDateto) {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      'font-weight': 'bold'
                    }
                  },
                  data.row.endDatefrom
                )
              ])
            } else {
              return h('div', [
                h(
                  'span',
                  {
                    attrs: {
                      class: 'deleted'
                    }
                  },
                  data.row.endDatefrom
                ),
                h('br'),
                h(
                  'span',
                  {
                    style: {
                      'font-weight': 'bold'
                    }
                  },
                  data.row.endDateto
                )
              ])
            }
          }
        },
        {
          title: '采购类型',
          render: (h, data) => {
            if (data.row.typetoName && data.row.typetoName !== '') {
              return h('div', [
                h(
                  'span',
                  {
                    attrs: {
                      class: 'deleted'
                    }
                  },
                  data.row.typefromName
                ),
                h('br'),
                h(
                  'span',
                  {
                    style: {
                      'font-weight': 'bold'
                    }
                  },
                  data.row.typetoName
                )
              ])
            } else {
              return h('span', data.row.typefromName)
            }
          }
        },
        { title: '变更人', key: 'userName' },
        { title: '变更时间', key: 'createTime' },
        {
          title: '变更类型',
          render: (h, data) => {
            return formatOrderChangeType(h, data.row.type, data.row.typeName)
          }
        }
      ]
    }
  },
  created () {
    if (this.orderId) {
      this.initData()
    }
  },
  methods: {
    initData () {
      const query = {
        orderId: this.orderId
      }
      orderResourceChanges(query).then(res => {
        this.list = res
      })
    }
  },
  watch: {
    orderId (val) {
      if (val) {
        this.initData()
      }
    }
  }
}
</script>
