import request from '@/utils/requestV2'
const qs = require('qs')

// 删除点播屏分组
export function deleteGroup (data) {
  return request({
    url: '/ooh-order/v1/productelectric/deletegroup',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 订单点播屏分组明细
export function getElectricGroupPage (data) {
  return request({
    url: '/ooh-order/v1/productelectric/getelectricgrouppage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 替换电子屏分组
export function replaceGroup (data) {
  return request({
    url: '/ooh-order/v1/productelectric/replacegroup',
    method: 'post',
    data: qs.stringify(data)
  })
}
