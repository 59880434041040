<template>
  <div>
    <Row class="p-b-5">
      <strong>档期：</strong>
      <div v-for="(schedule,i) in schedules" :key="i" @click="scheduleIndex=i"  :class="scheduleIndex === i?'workplatform-radio-button-active':'workplatform-radio-button'">{{getScheduleInfo(schedule)}}</div>
    </Row>
    <Row class="p-b-5">
        产品：
        <div v-for="(product,i) in products" :key="i" @click="productsIndex=i" :class="productsIndex === i?'workplatform-radio-button-active':'workplatform-radio-button'">{{product.productName}} {{product.priceType===1?'':'特价'}} </div>
    </Row>
    <Row class="p-b-5">
        明细：
        <div v-for="(item,i) in typeList" :key="i" @click="handleChangeCurrentType(i)" :class="currentType === i?'workplatform-radio-button-active':'workplatform-radio-button'">{{item.productName}}</div>
    </Row>

    <div v-if="typeList.length>0 && isRouterAlive">
      <review ref="reviewComponent" class="p-b-5" :customskuId="customskuId" :orderId='orderId' :product='currentProduct'/>
      <component ref="detailComponent" :is="getDetailComponent(typeList[currentType].type)" :hiddenOrderPoint="hiddenOrderPoint" :product="currentProduct" :orderId='orderId'  :productId="currentProduct.curProductId" :customskuId='customskuId' ></component>
    </div>

  </div>
</template>

<script>
import review from './Review'
import TrainDetail from './Train'
import ThemeDetail from './detail_theme'
import StairSignDetail from './detail_stairsign'
import MetroDetail from './detail_metro'
import MetroSeries from './detail_series'
import ElecChoose from './detail_pis'

import { getCustomInfo } from '@/api/order/custom'
import { getResourceCountByOrderId } from '@/api/order/productdetail'
import { GetCurrentSchedule } from '@/utils/dateFormat'

export default {
  props: {
    schedules: {
      type: Array,
      required: true
    },
    orderId: {
      type: [String, Number],
      required: true
    },
    hiddenOrderPoint: Boolean
  },
  components: {
    review,
    ThemeDetail,
    StairSignDetail,
    MetroDetail,
    TrainDetail,
    MetroSeries,
    ElecChoose
  },
  data () {
    return {
      products: [],
      scheduleIndex: 0,
      productsIndex: 0,
      currentProduct: {},

      isRouterAlive: true,
      customskuId: 0,
      currentType: 0,
      typeList: []
    }
  },
  methods: {
    getScheduleInfo (products) {
      if (products.length > 0) {
        const product = products[0]
        return GetCurrentSchedule(product.startDate, product.endDate)
      }
    },
    getDetailComponent (type) {
      const componentMap = {
        1: 'MetroDetail', // 平面媒体
        2: 'ThemeDetail', // 列车
        3: 'TrainDetail', // 导向媒体
        4: 'StairSignDetail', // 梯牌
        5: 'MetroDetail', // 平面媒体
        14: 'ElecChoose', // 点播屏
        16: 'MetroSeries' // 连封套装
      }
      return componentMap[type]
    },
    getGroupInfo () {
      this.customskuId = null
      const postData = {
        orderId: this.orderId,
        customproductId: this.currentProduct.productId
      }
      getCustomInfo(postData).then(res => {
        this.customskuId = res.customskuId
        this.typeList = res.productTypeBeanList
        if (res.productTypeBeanList.length > 0) {
          this.currentType = 0
          this.currentProduct.curProductId = this.typeList[this.currentType].productId
        }
        this.updateMapPostsite()
        this.isRouterAlive = false
        this.$nextTick(() => (this.isRouterAlive = true))
      })
    },
    handleChangeCurrentType (index) {
      this.currentType = index
      this.currentProduct.curProductId = this.typeList[index].productId
      // 重新查询产品
      this.updateMapPostsite()
      this.$refs.reviewComponent.initpage()
      this.$refs.detailComponent.initPurchaseList()
    },
    updateMapPostsite () {
      if (this.currentProduct.productType !== 4) {
        const searchData = {
          orderId: this.orderId,
          productId: this.currentProduct.curProductId,
          schedules: JSON.stringify([{ startDate: this.currentProduct.startDate, endDate: this.currentProduct.endDate }]),
          priceType: this.currentProduct.priceType,
          customskuId: this.customskuId
        }

        getResourceCountByOrderId(searchData).then(res => {
          this.$emit('on-detatil-postsite', res)
        })
      } else {
        this.$emit('on-detatil-postsite', [])
      }
    }
  },
  created () {
    this.products = this.schedules[0]
    this.currentProduct = this.products[0]
    this.getGroupInfo()
  },
  watch: {
    scheduleIndex: function () {
      this.productsIndex = 0
      this.products = this.schedules[this.scheduleIndex]
      this.currentProduct = this.products[this.productsIndex]
      this.currentProduct.curProductId = this.typeList[this.currentType].productId
      this.getGroupInfo()
    },
    productsIndex: function () {
      this.currentProduct = this.products[this.productsIndex]
      this.currentProduct.curProductId = this.typeList[this.currentType].productId
      this.getGroupInfo()
    }
  }

}
</script>
