<template>
  <div>
      <Row class="p-b-5">
        <strong>档期：</strong>
        <RadioGroup v-model="schedule" type="button" size="small">
            <Radio v-for="(schedule,i) in schedules" :key="i" :label="i" > {{getScheduleInfo(schedule)}} </Radio>
        </RadioGroup>
      </Row>
      <Row class="p-b-5">
        <strong>产品：</strong>
        <RadioGroup v-model="productsIndex" type="button" size="small">
            <Radio v-for="(product,i) in products" :key="i" :label="i" > {{product.productName}} {{product.priceType===1?'':'特价'}} </Radio>
        </RadioGroup>
      </Row>

        <!-- 北京电子屏需要使用地图 -->
      <review class="p-b-5" v-if="currentProduct.productType===18" :orderId='orderId' :product='currentProduct'/>
      <component v-if="shown" :is="getComponent(currentProduct.productType)" :orderId="orderId"  :product="currentProduct"></component>

  </div>
</template>

<script>
import review from './Review'
import pisDetail from './detail_pis'
import electronicDetail from './detail_electronic'
import detailMetro from './detail_metro'

import { GetCurrentSchedule } from '@/utils/dateFormat'
import { getResourceCountByOrderId } from '@/api/order/productdetail'

export default {
  props: {
    isOrder: { default: true },
    orderId: {
      type: [Number, String],
      required: true
    },
    schedules: {
      type: Array,
      required: true
    }
  },
  components: {
    review, pisDetail, detailMetro, electronicDetail
  },
  data () {
    return {
      products: [],
      schedule: 0,
      productsIndex: 0,
      currentProduct: {},
      shown: true
    }
  },
  created () {
    this.products = this.schedules[0]
    this.currentProduct = this.products[0]
    this.updateMapPostsite()
  },
  methods: {
    getScheduleInfo (products) {
      if (products.length > 0) {
        const product = products[0]
        return GetCurrentSchedule(product.startDate, product.endDate)
      }
    },
    getComponent (productType) {
      if (productType === 18) { // 北京电子屏
        return 'detailMetro'
      } else if (productType === 7) { // PIS屏
        return 'pisDetail'
      } else {
        return 'electronicDetail'
      }
    },
    updateMapPostsite () {
      if (!this.isOrder) { return }

      if (this.currentProduct.productType === 18) {
        const searchData = {
          orderId: this.orderId,
          productId: this.currentProduct.productId,
          schedules: JSON.stringify([{ startDate: this.currentProduct.startDate, endDate: this.currentProduct.endDate }]),
          priceType: this.currentProduct.priceType
        }

        getResourceCountByOrderId(searchData).then(res => {
          this.$emit('on-detatil-postsite', res)
        })
      } else {
        this.$emit('on-detatil-postsite', [])
      }
    }
  },
  watch: {
    schedule: function () {
      this.productsIndex = 0
      this.products = this.schedules[this.schedule]
      this.currentProduct = this.products[this.productsIndex]
      this.shown = false
      setTimeout(() => {
        this.shown = true
      }, 100)
      this.updateMapPostsite()
    },
    productsIndex: function () {
      this.currentProduct = this.products[this.productsIndex]
      this.shown = false
      setTimeout(() => {
        this.shown = true
      }, 100)
    }
  }

}
</script>
