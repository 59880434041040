import request from '@/utils/requestV2'
const qs = require('qs')

// 获取套装内产品信息
export function getCustomInfo (data) {
  return request({
    url: '/ooh-order/v1/custom/getcustominfo',
    method: 'POST',
    data: qs.stringify(data)
  })
}
