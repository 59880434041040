<template>
  <div>
    <h3 class="p-t-10">其他费用</h3>
    <Row>
        <i-col span="24" class="right">
            <Table  stripe size="small"  style="width:400px;float: inherit;" :row-class-name="rowClassName" :columns="serviceColumns" :data="serviceData" border></Table>
        </i-col>
    </Row>
    <Row class="p-t-5">
      <i-col span="24" class="text-right p-r-20">
        费用合计：{{formatMoney(discountPrice)}}
      </i-col>
    </Row>
  </div>
</template>

<script>
import { getSummaryPrice } from '@/api/order/orderprice'
import { toMoney } from '@/utils/wnumb_own'

export default {
  data () {
    return {
      serviceColumns: [
        {
          title: '明细类别',
          width: 180,
          render: (h, params) => {
            return h('span', params.row.productName + ' - ' + params.row.ruleTypeName)
          }
        },
        { title: '刊例折扣', align: 'center', key: 'value' },
        {
          title: '费用',
          width: 130,
          align: 'right',
          render: (h, params) => {
            return h('span', this.formatMoney(params.row.usePrice))
          }
        }
      ],
      serviceData: [],
      discountPrice: 0
    }
  },
  methods: {
    rowClassName (row, index) {
      if (index % 2 === 0) {
        return 'ivu-table-stripe-even'
      } else return 'ivu-table-stripe-odd'
    },
    initPrice (orderId) { // 显示增值服务费明细
      getSummaryPrice({ orderId: orderId }).then(res => {
        this.serviceData = res.rulePriceResultList
        this.discountPrice = res.finalUsePrice - res.totalUsePrice

        this.$emit('summaryPrice', this.discountPrice)
      })
    },
    formatMoney (number) {
      return toMoney(number)
    }
  }
}
</script>
