<template>
  <div>
    <OrderCostBasics ref="orderCostBasics" @summaryPrice="hanldeSummaryPrice"/>
    <OrderCostService ref="orderCostService" @summaryPrice="hanldeSummaryServicePrice"/>
    <OrderCostOther ref="orderCostOther" @summaryPrice="hanldeSummaryOtherPrice"/>

    <Divider style="margin: 5px 0;"/>
    <Row class="m-b-10">
      <i-col span="12">
        <span class="remark" v-if="orderServicePrice>0">PS：服务费为固定费用，最终价格以实际上刊设置为准</span>
      </i-col>
      <i-col span="12" class="text-right text-16 p-r-20">
        订单总价：<b class="text-orange">{{showOrderPrice}}</b>
      </i-col>
    </Row>

    <OrderCostServiceNew ref="orderCostService2"/>

  </div>
</template>

<script>
import OrderCostBasics from '../common/OrderCostBasics'
import OrderCostOther from '../common/OrderCostOther'
import OrderCostService from '../common/OrderCostService'
import OrderCostServiceNew from '../common/OrderCostService_New'
import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    products: {
      type: Array,
      required: true
    },
    orderId: {
      type: Number,
      required: true
    }
  },
  components: {
    OrderCostBasics, OrderCostOther, OrderCostService, OrderCostServiceNew
  },
  data () {
    return {
      orderTotalPrice: 0,
      orderOtherPrice: 0,
      orderServicePrice: 0
    }
  },
  mounted () {
    this.initOrderPrice()
  },
  methods: {
    initOrderPrice () {
      this.$refs.orderCostService.initPrice(this.orderId)
      this.$refs.orderCostService2.initPrice(this.orderId)

      this.$refs.orderCostOther.initPrice(this.orderId)
      this.$refs.orderCostBasics.initPrice(this.products)
    },
    hanldeSummaryPrice (price) {
      this.orderTotalPrice = price
    },
    hanldeSummaryOtherPrice (price) {
      this.orderOtherPrice = price
    },
    hanldeSummaryServicePrice (price) {
      this.orderServicePrice = price
    }
  },
  computed: {
    detailReload () {
      return this.$store.state.order ? this.$store.state.order.detailReload : null
    },
    showOrderPrice () {
      const totalPrice = this.orderServicePrice + this.orderOtherPrice + this.orderTotalPrice
      return toMoney(totalPrice)
    }
  },
  watch: {
    products (val) {
      this.$refs.orderCostBasics.initPrice(val)
    },
    detailReload () {
      // 服务费采购暂时已被屏蔽
      // this.$refs.orderCostService.initPrice(this.orderId)
      // this.$refs.orderCostService2.initPrice(this.orderId)
    }
  }
}
</script>
