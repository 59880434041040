import request from '@/utils/requestV2'
const qs = require('qs')

// 删除连封(组)
export function deleteContinuous (data) {
  return request({
    url: '/ooh-order/v1/continuous/deletecontinuous',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 根据订单获取产品下连封资源明细(按组)分页
export function getContinuousPage (data) {
  return request({
    url: '/ooh-order/v1/continuous/getpage',
    method: 'POST',
    data: qs.stringify(data)
  })
}
