<template>
  <div>
    <h3 class="p-t-10">媒体发布费</h3>
    <Table stripe size="small" :columns="productColumns" :data="productData" border disabled-hover :span-method="handleProductSpan" />
    <Row class="p-t-5">
      <i-col span="24" class="text-right p-r-20">
        发布费刊例价合计：{{totalUsePrice}}
      </i-col>
    </Row>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { GetCurrentSchedule } from '@/utils/dateFormat'

export default {
  data () {
    return {
      productData: [],
      productColumns: [
        { title: '发布档期', key: 'schedule' },
        { title: '媒体类型', key: 'productName' },
        { title: '单品类型', key: 'productskuName' },
        {
          title: '刊例价',
          render: (h, data) => {
            return h('span', toMoney(data.row.usePrice))
            // return h('span', toMoney(data.row.usePrice) + ' / ' + data.row.productUnit)
          }
        },
        {
          title: '采购数量',
          render: (h, data) => {
            return h('span', data.row.quantity + ' ' + data.row.productUnit)
          }
        },
        {
          title: '刊例价小计',
          align: 'right',
          render: (h, data) => {
            return h('span', toMoney(data.row.totalUsePrice))
          }
        }
      ],
      totalUsePrice: 0,
      scheduleColumSpan: [], // 档期列合并项
      mediumTypeColumSpan: [] // 媒体类型列合并项
    }
  },
  methods: {
    initPrice (products) { // 显示采购产品明细
      const getProductList = []
      let summary = 0

      products.forEach(product => {
        product.orderProductskuList.forEach(sku => {
          getProductList.push({
            schedule: GetCurrentSchedule(product.startDate, product.endDate),
            productName: product.productName,
            productId: product.productId,

            productskuName: sku.productskuName,
            quantity: sku.quantity,
            productUnit: sku.productUnit,
            usePrice: sku.showTotalUsePrice,
            totalUsePrice: sku.totalUsePrice
          })

          summary += sku.totalUsePrice
        })
      })

      this.productData = getProductList.sort(function (a, b) {
        return (a.schedule >= b.schedule && a.productId >= b.productId) ? 1 : -1
      })

      this.totalUsePrice = toMoney(summary)
      this.$emit('summaryPrice', summary)
      this.formatTable()
    },
    formatTable () {
      let exsitSchedule = null
      let exsitMedium = null
      this.scheduleColumSpan = []
      this.mediumTypeColumSpan = []

      for (let index = 0; index < this.productData.length; index++) {
        // 获取合并的档期项
        exsitSchedule = this.scheduleColumSpan.find(x => x.schedule === this.productData[index].schedule)
        if (exsitSchedule) {
          exsitSchedule.end += 1
        } else {
          this.scheduleColumSpan.push({
            schedule: this.productData[index].schedule,
            start: index,
            end: index + 1
          })
        }

        // 获取合并的媒体类型项
        exsitMedium = this.mediumTypeColumSpan.find(m => m.productName === this.productData[index].productName && m.schedule === this.productData[index].schedule)
        if (exsitMedium) {
          exsitMedium.end += 1
        } else {
          this.mediumTypeColumSpan.push({
            productName: this.productData[index].productName,
            schedule: this.productData[index].schedule,
            start: index,
            end: index + 1
          })
        }
      }
    },
    handleProductSpan ({ row, column, rowIndex, columnIndex }) {
      // 档期列合并
      if (columnIndex === 0) {
        for (let index = 0; index < this.scheduleColumSpan.length; index++) {
          if (rowIndex === this.scheduleColumSpan[index].start) {
            return [this.scheduleColumSpan[index].end - this.scheduleColumSpan[index].start, 1]
          } else if (rowIndex < this.scheduleColumSpan[index].end) {
            return [0, 0]
          }
        }
      }
      // 媒体类型列合并
      if (columnIndex === 1) {
        for (let index = 0; index < this.mediumTypeColumSpan.length; index++) {
          if (rowIndex === this.mediumTypeColumSpan[index].start) {
            return [this.mediumTypeColumSpan[index].end - this.mediumTypeColumSpan[index].start, 1]
          } else if (rowIndex < this.mediumTypeColumSpan[index].end) {
            return [0, 0]
          }
        }
      }
    }
  }
}
</script>
