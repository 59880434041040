<template>
  <div>
    <Row :gutter="8" class="m-b-5">
      <i-col span="4">
        <Select size="small" v-model="query.orderStatus" placeholder="订单状态" clearable>
          <Option v-show="allowStatus.indexOf(status.value)>-1" v-for="(status,index) in statuslist" :key="index"
            :value="status.value">{{status.name}}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <DatePicker size="small" :show-week-numbers="true" type="daterange" v-model="orderSchdule"
          @on-change="scheduleChange" style="width: 100%" placement="top-start" placeholder="订单档期"></DatePicker>
      </i-col>
      <i-col span="8">
        <i-input size="small" v-model='query.keyword' placeholder="编号/客户名称/销售名称"></i-input>
      </i-col>
      <i-col span="6">
        <Button size="small" type="primary" icon="ios-search" @click="SearchOrders()">搜索</Button>
        <Button size="small" type="success" class="m-l-5" @click="handleExportExcel"
          >订单导出</Button>
      </i-col>
    </Row>

    <Table size="small" stripe :columns="columndata" :data="tableData" ></Table>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="query.pageSize" :current="query.pageNumber" show-total show-elevator
        @on-change="changePage"></Page>
    </div>
    <OrderDetail ref="orderDetail" />
    <Modal
      title="调整订单所属销售"
      v-model="ChangeorderSalermodel"
      :mask-closable="false"
      @on-ok="confirmChangeCustomerUser"
    >
      <Row class="p-t-10 p-b-5">
        当前所属销售：
        <strong>{{ currentUserName }}</strong>
      </Row>
      <Row>
        变更销售：
        <Select
          class="p-b-5"
          v-model="choiceSalerId"
          placeholder="选择销售人员"
        >
          <Option
            v-for="user in choicechannelUsers"
            :key="user.id"
            :value="user.id"
            >{{ user.name }}</Option
          >
        </Select>
      </Row>
    </Modal>
  </div>
</template>

<script>
import OrderDetail from '@/components/order/common/ModalOrderDetail'

import { formatOrderStatus } from '@/utils/tagStatus'
import { GetCurrentSchedule, GetDateStr } from '@/utils/dateFormat'
import { downloadFileRequest } from '@/utils/download'
import { toMoney } from '@/utils/wnumb_own'

// import { getOrderPage } from '@/api/order/order'
import { getorderstatus, getOMorderpage, modifyOmOrderSeller } from '@/api/order/om/order'
import { getSellerPage } from '@/api/os/companyuser'

export default {
  components: { OrderDetail },
  data () {
    return {
      orderSchdule: [],
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 15,
        publisherId: this.$store.getters.token.userInfo.publisherId,
        orderStatus: '',
        startDate: '',
        endDate: '',
        keyword: '',
        removeOverdue: true
      },
      statuslist: [],
      orderTypeList: [], // 订单发布类型
      searchStatus: '',
      allowStatus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 13],
      tableData: [],
      columndata: [
        {
          title: '编号',
          key: 'orderCode'
        },
        {
          title: '客户名称',
          render: (h, params) => {
            return h('span', params.row.omAdvertiserVO.advertiserName)
          }
        },

        // {
        //   title: '投放档期',
        //   key: 'startDate',
        //   sortable: 'custom',
        //   render: (h, params) => {
        //     return h('span', GetCurrentSchedule(params.row.startDate, params.row.endDate))
        //   }
        // },
        {
          title: '投放档期',
          render: (h, data) => {
            const spans = []
            spans.push(h('span', GetCurrentSchedule(data.row.startDate, data.row.endDate)))
            return h('div', spans)
          }
        },

        {
          title: '采购资源统计',
          render: (h, params) => {
            const spans = []
            params.row.resourceTypeVOList.forEach(resource => {
              spans.push(h(
                'span',
                resource.resourcetypeName
              ))
              spans.push(h(
                'span',
                ' * '
              ))
              spans.push(h(
                'span',
                {
                  attrs: {
                    class: 'text-orange text-12'
                  }
                },
                resource.count
              ))
              spans.push(h('br'))
            })
            return h('div', {

            }, spans)
          }
        },
        {
          title: '订单总价',
          render: (h, params) => {
            return h('div', [h('p', toMoney(params.row.totalPrice))])
          }
        },
        {
          title: '一级销售',
          render: (h, params) => {
            return h('span', params.row.omSalerVO.userName)
          }
        },
        {
          title: '二级销售',
          render: (h, params) => {
            return h('span', params.row.omSalerVO.secondUserName || '-')
          }
        },
        {
          title: '状态',
          align: 'center',
          width: 100,
          render: (h, params) => {
            return formatOrderStatus(h, params.row.status, params.row.statusName)
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            const detailButton =
              h('a', {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.handleShowDetail(params.row.orderId)
                  }
                }
              }, '详情')

            const updateSalerButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.showChangeSalerModel(params.row)
                  }
                }
              },
              '调整销售'
            )
            return h('div', [detailButton, updateSalerButton])
          }
        }
      ],
      ChangeorderSalermodel: false,
      choicechannelUsers: [],
      currentUserName: '',
      choiceSalerId: 0,
      changeOrderId: 0
    }
  },
  created () {
    getorderstatus().then(res => {
      this.statuslist = res
    })
    this.initOrderList()
  },
  methods: {

    initOrderList () {
      // const state = []
      // this.query.status = ''
      // if (this.searchStatus === undefined || this.searchStatus === '') {
      //   this.query.orderStatus = JSON.stringify(this.allowStatus)
      // } else {
      //   state.push(this.searchStatus)
      //   this.query.orderStatus = JSON.stringify(state)
      // }
      getOMorderpage(this.query).then(response => {
        this.total = response.total
        this.tableData = response.records
        this.query.pageNumber = response.pages
      })
    },
    scheduleChange () {
      const shcedule = this.orderSchdule
      if (shcedule[0] === '' || shcedule[1] === '') {
        this.query.startDate = ''
        this.query.endDate = ''
      } else {
        this.query.startDate = GetDateStr(shcedule[0], 1)
        this.query.endDate = GetDateStr(shcedule[1], 1)
      }
    },
    SearchOrders () {
      this.query.pageNumber = 1
      this.initOrderList()
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initOrderList()
    },
    handleShowDetail (orderId) {
      this.$store.commit('set_openmarketorder_ID', orderId)
      this.$store.commit('set_openmarketorder_pageType', 'detail')
      // 设置传递参数
      const query = { orderId: orderId }
      this.$store.dispatch('getOMOrderBaseInfo', query)
    },
    handleExportExcel () {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-order/om/v1/report/download-order-list-excel', this.query, '订单列表.xlsx')
    },

    showChangeSalerModel (params) {
      this.currentUserName = params.omSalerVO.userName
      this.choiceSalerId = params.omSalerVO.userId
      this.changeOrderId = params.orderId
      const data = {
        companyId: params.omSalerVO.userCompanyId,
        pageNumber: 1,
        pageSize: 1000
      }
      getSellerPage(data).then((response) => {
        this.choicechannelUsers = response.list
      })

      this.ChangeorderSalermodel = true
    },
    // 确认变更客户所属
    confirmChangeCustomerUser () {
      modifyOmOrderSeller({ orderId: this.changeOrderId, newSellerId: this.choiceSalerId }).then(res => {
        if (res) {
          this.$Notice.success({ desc: '成功变更订单所属销售关系！' })
          this.ChangeorderSalermodel = false
          this.initOrderList()
        } else {
          this.ChangeorderSalermodel = true
        }
      })
    }
  }
}
</script>
