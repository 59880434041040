import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取已采购的服务项明细
 * @param {Object} data
 * @returns
 */
export function getPurchasedItemList (data) {
  return request.post('/ooh-order/v2/orderservice/getpurchaseditemlist', qs.stringify(data))
}

/**
 * 获取已采购的服务项按档期-产品-类目分组
 * @param {Object} data
 * @returns
 */
export function getItemTypePriceList (data) {
  return request.post('/ooh-order/v2/orderservice/getitemtypepricelist', qs.stringify(data))
}

/**
 * 删除项
 * @param {Object} data
 * @returns
 */
export function deleteItem (data) {
  return request.post('/ooh-order/v2/orderservice/deleteitem', qs.stringify(data))
}

/**
 * 获取可采购的服务项
 * @param {Object} data
 * @returns
 */
export function getItemList (data) {
  return request.post('/ooh-order/v2/orderservice/getitemlist', qs.stringify(data))
}

/**
 * 服务采购
 * @param {Object} data
 * @returns
 */
export function addItems (data) {
  return request.post('/ooh-order/v2/orderservice/additems', qs.stringify(data))
}

/**
 * 获取已采购的服务项 -新
 * @param {Object} data
 * @returns
 */
export function getProductPriceList (data) {
  return request.post('/ooh-order/v2/orderservice/getProductPriceList', qs.stringify(data))
}
