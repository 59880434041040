<template>
    <div>
      <Row class="p-b-5">
          档期：
          <div v-for="(schedule,i) in schedules" :key="i" @click="scheduleIndex=i" class="m-b-2" :class="scheduleIndex === i?'workplatform-radio-button-active':'workplatform-radio-button'">{{getScheduleInfo(schedule)}}</div>
      </Row>
      <Row class="p-b-5">
          产品：
          <div v-for="(product,i) in products" :key="i" @click="productsIndex=i" :class="productsIndex === i?'workplatform-radio-button-active':'workplatform-radio-button'">{{product.productName}} {{product.priceType===1?'':'特价'}} </div>
      </Row>

      <review class="p-b-5" v-if="!disableMap.includes(currentProduct.productType)" :orderId='orderId' :product='currentProduct'/>

      <component v-if="shown" :is="getComponent(currentProduct.productType)" :orderId="orderId"  :product="currentProduct"></component>
    </div>
</template>

<script>
import review from './Review'
import detailMetro from './detail_metro'
import detailTheme from './detail_theme'
import detailStairsign from './detail_stairsign'
import detailSeries from './detail_series'
import detailTrain from './detail_train'

import { GetCurrentSchedule } from '@/utils/dateFormat'
import { getResourceCountByOrderId } from '@/api/order/productdetail'

export default {
  components: {
    review, detailMetro, detailTheme, detailStairsign, detailSeries, detailTrain
  },
  props: {
    isOrder: { default: true },
    schedules: {
      type: Array,
      required: true
    },
    orderId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      scheduleIndex: 0,
      products: [],
      productsIndex: 0,
      currentProduct: {},
      disableMap: [3, 4],
      shown: true
    }
  },
  created () {
    this.products = this.schedules[0]
    this.currentProduct = this.products[0]
    this.updateMapPostsite()
  },
  methods: {
    getScheduleInfo (products) {
      if (products.length > 0) {
        const product = products[0]
        return GetCurrentSchedule(product.startDate, product.endDate)
      }
    },
    getComponent (productType) {
      const componentMap = {
        1: 'detailMetro', // 地铁灯箱
        2: 'detailTheme', // 主题媒体
        3: 'detailTrain', // 列车
        4: 'detailStairsign', // 梯牌
        16: 'detailSeries', // 连封灯箱
        5: 'detailMetro' // 候车亭灯箱，暂时用地铁灯箱的
      }

      return componentMap[productType]
    },
    updateMapPostsite () {
      if (!this.isOrder) { return }

      if (!this.disableMap.includes(this.currentProduct.productType)) {
        const searchData = {
          orderId: this.orderId,
          productId: this.currentProduct.productId,
          schedules: JSON.stringify([{ startDate: this.currentProduct.startDate, endDate: this.currentProduct.endDate }]),
          priceType: this.currentProduct.priceType
        }

        getResourceCountByOrderId(searchData).then(res => {
          this.$emit('on-detatil-postsite', res)
        })
      } else {
        this.$emit('on-detatil-postsite', [])
      }
    }
  },
  watch: {
    scheduleIndex: function () {
      this.productsIndex = 0
      this.products = this.schedules[this.scheduleIndex]
      this.currentProduct = this.products[this.productsIndex]
      // 用于强行刷新组件
      this.shown = false
      setTimeout(() => {
        this.shown = true
      }, 100)
      this.updateMapPostsite()
    },
    productsIndex: function () {
      this.currentProduct = this.products[this.productsIndex]
      if (this.isOrder) {
        this.$store.commit('set_order_categoryId', this.currentProduct.categoryId)
      }
      // 用于强行刷新组件
      this.shown = false
      setTimeout(() => {
        this.shown = true
      }, 100)
      this.updateMapPostsite()
    }

  }
}
</script>
