<template>
    <div>
        <!-- <Row :gutter="8" class="m-b-5">
            <i-col span="24">
            <i-input  v-model="query.keyword" placeholder="关键字">
                <Button slot="append" icon="ios-search" @click="ReLoadData"></Button>
            </i-input>
            </i-col>
        </Row> -->

        <Table :columns="ThemeColumndata" :data="tableData"  :row-class-name="rowClassName" size="small" stripe></Table>
        <div class="paging_style">
            <Page size="small"  :total="total" :page-size="query.pageSize" show-total show-elevator :current="query.pageNumber" @on-change="changePage"></Page>
        </div>
    </div>
</template>

<script>
import { getOrderFixPage } from '@/api/order/productfix'
import { formatOrderSaleType } from '@/utils/tagStatus'
import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    orderId: {
      type: [Number, String],
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    customskuId: {
      type: Number
    }
  },
  data () {
    return {
      tableData: [],
      ThemeColumndata: [ // 显示主题媒体
        { title: '名称', key: 'productskuName' },
        { title: '所属产品', width: 120, key: 'productName' },
        {
          title: '媒介使用费',
          width: 150,
          render: (h, params) => {
            return h('span',
              toMoney(params.row.usePrice)
            )
          }
        },
        {
          title: '采购类型',
          align: 'center',
          width: 120,
          render: (h, params) => {
            return formatOrderSaleType(h, params.row.type)
          }
        }
      ],
      query: {
        orderId: this.orderId,
        productId: this.product.productId,
        subitemproductId: this.product.subitemproductId,
        pageNumber: 1,
        pageSize: 10,
        keyword: '',
        startDate: this.product.startDate,
        endDate: this.product.endDate,
        priceType: this.product.priceType
      },
      total: 0,
      currentProduct: {}
    }
  },
  created () {
    this.initPurchaseList()
  },
  methods: {
    rowClassName (row, index) {
      return row.conflict ? 'table-conflict-row' : ''
    },
    initPurchaseList () {
      this.query.productId = this.customskuId ? this.product.curProductId : this.product.productId
      this.query.orderId = this.orderId
      this.query.customskuId = this.customskuId || undefined
      getOrderFixPage(this.query).then(res => {
        this.tableData = res.list
        this.total = res.totalRow
        this.query.pageNumber = res.pageNumber
      })
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPurchaseList()
    },
    ReLoadData () {
      this.query.pageNumber = 1
      this.initPurchaseList()
    }
  },
  watch: {
    productId: function () {
      this.initPurchaseList()
    }
  }
}
</script>
