<template>
    <div>
        <Table :columns="columns2" :data="list2" size="small" stripe></Table>
        <div class="paging_style">
            <Page size="small" :total="total" :page-size="query.pageSize" :current="query.pageNumber" show-total show-elevator @on-change="handlePageNumberChange"></Page>
        </div>
    </div>
</template>

<script>
import { getProductDetailPageByOrder } from '@/api/order/productdetail'

import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    orderId: {
      type: [Number, String],
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    customskuId: {
      type: Number
    },
    hiddenOrderPoint: Boolean
  },
  data () {
    return {
      columns2: [
        { title: '所属资产', key: 'assetName' },
        { title: '所属单品', key: 'productskuName' },
        { title: '投放方式', key: 'stationTypeName' },
        {
          title: '投放规格',
          render: (h, params) => {
            return h('span', params.row.duration + '秒')
          }
        },
        {
          title: '投放时长',
          render: (h, params) => {
            return h('span', params.row.tduration + '秒')
          }
        },
        {
          title: '媒介使用费',
          render: (h, params) => {
            return h('span',
              toMoney(params.row.useprice)
            )
          }
        }
      ],
      list2: [],
      query: { pageNumber: 1, pageSize: 10 },
      total: 0
    }
  },
  created () {
    this.initPurchaseList()
  },
  methods: {
    initPurchaseList () {
      this.query.orderId = this.orderId
      this.query.productId = this.customskuId ? this.product.curProductId : this.product.productId
      this.query.customskuId = this.customskuId || undefined
      this.query.startDate = this.product.startDate
      this.query.endDate = this.product.endDate

      getProductDetailPageByOrder(this.query).then(res => {
        this.total = res.totalRow
        this.list2 = res.list
      })
    },
    handlePageNumberChange (currentPage) {
      this.query.pageNumber = currentPage
      this.initPurchaseList()
    }
  }
}
</script>
