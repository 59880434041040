<template>
    <div>
        <Row class="p-b-5">
            <RadioGroup v-model="productId" type="button" size="small">
                <Radio v-for="product in products" :key="product.productId" :label="product.productId" > {{product.productName}} </Radio>
            </RadioGroup>
        </Row>

        <Table  stripe :columns="purchaseColumn" :data="purchaseData" :row-class-name="rowClassName" size="small"></Table>
        <div class="paging_style">
            <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator :current="query.pageNumber" @on-change="changePage"></Page>
        </div>
    </div>
</template>

<script>
import { getProductDetailPageByOrder } from '@/api/order/productdetail'
import { GetPublishDay } from '@/utils/dateFormat'

export default {
  props: {
    schedules: {
      type: Array,
      required: true
    },
    orderId: {
      type: [String, Number],
      required: true
    },
    customskuId: {
      type: Number
    }
  },
  data () {
    return {
      productId: 0,
      products: [],
      purchaseData: [],
      purchaseColumn: [
        { title: '列车编号', key: 'resourceCode' },
        { title: '列车类型', key: 'stationName' },
        { title: '所属产品', key: 'productName' },
        { title: '投放位置', key: 'assetName' },
        {
          title: '投放日期',
          render: (h, data) => {
            return h('div', [
              h('span', data.row.startDate),
              h('span', ' 至 '),
              h('span', data.row.endDate)
            ])
          }
        },
        {
          title: '采购天数',
          align: 'center',
          width: 100,
          render: (h, data) => {
            return h('span', GetPublishDay(data.row.startDate, data.row.endDate))
          }
        }
      ],
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 10
      },
      currentProduct: {}
    }
  },
  created () {
    const products = new Map()
    this.schedules.forEach(sc => {
      sc.forEach(product => {
        if (!products.has(product.productId)) {
          products.set(product.productId, product)
        }
      })
    })
    this.products = [...products.values()]
    if (this.products.length > 0) {
      this.productId = this.products[0].productId
    }
  },
  methods: {
    rowClassName (row, index) {
      return row.conflict ? 'table-conflict-row' : ''
    },
    initPurchaseList () {
      if (this.product) {
        this.query.orderId = this.orderId
        this.query.productId = this.product.productId
        this.query.subitemproductId = this.product.subitemproductId
        this.query.productId = this.customskuId ? this.product.curProductId : this.product.productId
        this.query.orderId = this.orderId
        this.query.customskuId = this.customskuId || undefined
        this.query.keyword = ''
        this.query.startDate = this.product.startDate
        this.query.endDate = this.product.endDate
        this.query.priceType = this.product.priceType
      } else {
        this.query.productId = this.productId
        this.query.orderId = this.orderId
        this.query.customskuId = this.customskuId || undefined
      }

      getProductDetailPageByOrder(this.query).then(res => {
        this.purchaseData = res.list
        this.total = res.totalRow
        this.query.pageNumber = res.pageNumber
      })
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPurchaseList()
    }
  },
  watch: {
    productId: function () {
      this.query.pageNumber = 1
      this.initPurchaseList()
    }
  }

}
</script>
