<template>
  <div>
    <Table :columns="Staircolumndata"  :row-class-name="rowClassName" :data="tableData" size="small" stripe></Table>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator :current="query.pageNumber" @on-change="changePage"></Page>
    </div>
  </div>
</template>

<script>
import { getProductDetailPageByOrder } from '@/api/order/productdetail'

export default {
  props: {
    orderId: {
      type: [Number, String],
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    customskuId: {
      type: Number
    }
  },
  data () {
    return {
      tableData: [],
      Staircolumndata: [ // 显示梯牌
        {
          title: '站台名称',
          key: 'stationName'
        },
        {
          title: '站点出口',
          key: 'exit'
        },
        {
          title: '资源位置',
          key: 'positionName'
        },
        {
          title: '梯牌序号',
          key: 'resourceCode'
        },
        {
          title: '站点级别',
          key: 'stationLevelName'
        },
        {
          title: '所属资产',
          key: 'assetName'
        }
      ],
      query: {
        orderId: this.orderId,
        productId: this.customskuId ? this.product.curProductId : this.product.productId,
        subitemproductId: this.product.subitemproductId,
        pageNumber: 1,
        pageSize: 15,
        keyword: '',
        startDate: this.product.startDate,
        endDate: this.product.endDate,
        priceType: this.product.priceType
      },
      total: 0,
      currentProduct: {}
    }
  },
  created () {
    this.initPurchaseList()
  },
  methods: {
    rowClassName (row, index) {
      return row.conflict ? 'table-conflict-row' : ''
    },
    initPurchaseList () {
      this.query.productId = this.customskuId ? this.product.curProductId : this.product.productId
      this.query.orderId = this.orderId
      this.query.customskuId = this.customskuId || undefined
      getProductDetailPageByOrder(this.query).then(res => {
        this.tableData = res.list
        this.total = res.totalRow
        this.query.pageNumber = res.pageNumber
      })
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPurchaseList()
    }
  },
  watch: {
    productId: function () {
      this.initPurchaseList()
    }
  }
}
</script>
