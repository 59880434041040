<template>
  <div v-if="serviceData.length>0">
    <h3 class="p-t-10">服务费</h3>
    <Table  stripe size="small" :columns="serviceColumns" :data="serviceData" border disabled-hover :span-method="handleProductSpan" />
    <Row class="p-t-5">
      <i-col span="24" class="text-right text-16 p-r-20">
        服务费合计：<b class="text-green">{{serviceSummary}}</b>
      </i-col>
    </Row>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { GetCurrentSchedule } from '@/utils/dateFormat'
import { getProductPriceList } from '@/api/order/orderserviceV2'

export default {
  data () {
    return {
      serviceColumns: [
        { title: '发布档期', key: 'schedule' },
        { title: '单品类型', key: 'customProductSkuName' },
        {
          title: '可选服务内容',
          render: (h, data) => {
            return h('span', data.row.typeName + ' - ' + data.row.name)
          }
        },
        { title: '服务单价', key: 'price' },
        { title: '采购数量', key: 'quantity' },
        { title: '服务费小计', align: 'right', key: 'totalPrice' }
      ],
      serviceData: [],
      scheduleColumSpan: [],
      mediumTypeColumSpan: [],

      serviceSummary: 0
    }
  },
  methods: {
    initPrice (orderId) {
      getProductPriceList({ orderId: orderId }).then(res => {
        this.serviceData = []
        let summary = 0

        res.forEach(element => {
          element.servicePrice.forEach(item => {
            this.serviceData.push({
              schedule: GetCurrentSchedule(element.startDate, element.endDate),
              customProductSkuId: element.customProductSkuId,
              customProductSkuName: element.customProductSkuName,
              name: item.serviceItemName,
              typeName: item.typeName,
              price: toMoney(item.price),
              quantity: item.serviceQuantity + ' ' + item.serviceQuantityName,
              totalPrice: toMoney(item.price * item.serviceQuantity)
            })
            summary += item.price * item.serviceQuantity
          })
        })

        this.serviceSummary = toMoney(summary)
        this.formatTable()
      })
    },
    formatTable () { // 格式化表格，预算出需要合并显示的列
      let exsitSchedule = null
      let exsitMedium = null
      this.scheduleColumSpan = []
      this.mediumTypeColumSpan = []

      for (let index = 0; index < this.serviceData.length; index++) {
        // 获取合并的档期项
        exsitSchedule = this.scheduleColumSpan.find(x => x.schedule === this.serviceData[index].schedule)
        if (exsitSchedule) {
          exsitSchedule.end += 1
        } else {
          this.scheduleColumSpan.push({
            schedule: this.serviceData[index].schedule,
            start: index,
            end: index + 1
          })
        }

        // 获取合并的媒体类型项
        exsitMedium = this.mediumTypeColumSpan.find(m => m.customProductSkuId === this.serviceData[index].customProductSkuId && m.schedule === this.serviceData[index].schedule)
        if (exsitMedium) {
          exsitMedium.end += 1
        } else {
          this.mediumTypeColumSpan.push({
            customProductSkuId: this.serviceData[index].customProductSkuId,
            customProductSkuName: this.serviceData[index].customProductSkuName,
            schedule: this.serviceData[index].schedule,
            start: index,
            end: index + 1
          })
        }
      }
    },
    handleProductSpan ({ row, column, rowIndex, columnIndex }) {
      // 档期列合并
      if (columnIndex === 0) {
        for (let index = 0; index < this.scheduleColumSpan.length; index++) {
          if (rowIndex === this.scheduleColumSpan[index].start) {
            return [this.scheduleColumSpan[index].end - this.scheduleColumSpan[index].start, 1]
          } else if (rowIndex < this.scheduleColumSpan[index].end) {
            return [0, 0]
          }
        }
      }

      // 媒体类型列合并
      if (columnIndex === 1) {
        for (let index = 0; index < this.mediumTypeColumSpan.length; index++) {
          if (rowIndex === this.mediumTypeColumSpan[index].start) {
            return [this.mediumTypeColumSpan[index].end - this.mediumTypeColumSpan[index].start, 1]
          } else if (rowIndex < this.mediumTypeColumSpan[index].end) {
            return [0, 0]
          }
        }
      }
    }
  }
}
</script>
